
/**
 * @name: 商品管理-商品信息管理
 * @author:
 * @date: 2023-08-04 17:53
 * @description： 商品管理-商品信息管理
 * @update: 2023-08-04 17:53
 */
import {Vue, Component} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {IProductQuery} from "@/apis/product/info/types";
import {
  productCreateApi, productDetailApi, productEditSellingPriceApi, productExportApi,
  productGetListApi, productGetPriceUnitListApi, productGetProductAttrListApi,
  productGetSortListApi,
  productGetUnitListApi, productModifyApi,
  productQueryApi, productQuerySalesPriceDetailsApi,
  productUpdateStatusApi
} from "@/apis/product/info";
import config from "@/config";
import {Message} from "element-ui";
import {gateGetGateListApi} from "@/apis/gate/info";
import {IGate} from "@/apis/gate/info/types";
import {deepCopy, exportFile} from "@/utils/common";
import {ICheckinQuery} from "@/apis/storage/checkin/types";
import {isTaken} from "echarts/types/src/component/helper/interactionMutex";

@Component({})
export default class storageInventory extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: any = []
  // 商品售价表格数据
  tableForm: any = {
    goodsTableData: []
  }
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IProductQuery = {
    page: 1,
    limit: 10
  }
  // 商品售价弹出
  goodsPriceDialog: boolean = false
  // 新增修改弹出
  addDialog: boolean = false
  // 新增修改弹出标题
  addDialogTitle: string = ''
  goodsPriceDialogTitle: string = ''
  // 基础单位列表
  baseUnitList: any = []
  // 基础单位禁用列表
  baseUnitListDisabled: any = []
  // 商品列表
  goodsList: any = []
  // 商品分类
  goodsSortList: any = []
  // 详情基础单位
  baseUnitDetail: string = ''
  // 档口下拉
  gateList: IGate[] = []
  // 商品绑定单位列表
  priceUnitList: any = []
  // 价格属性列表
  productAttrList: any = []
  // 表单参数
  modelForm: any = {
    // productUnitBindDtos: []
  }
  // 售价
  loading: boolean = false
  // 商品
  goodsLoading: boolean = false
  // 编辑详情控制
  tableDetail: boolean = false
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    labelWidth: '150px',
    dialogWidth: '70%',
    column: [
      {
        label: "商品",
        prop: 'productNameOrSn',
        hide: true,
        placeholder: "输入商品编号/名称模糊搜索",
        search: true,
        addHide: true,
        editHide: true,
        viewHide: true,
      },
      {
        label: "商品ID",
        prop: 'id',
        align: "center",
        addHide: true,
        editHide: true,
        slot: true,
        width: "170"
      },
      {
        label: "商品编号",
        prop: 'productSn',
        align: "center",
        addHide: true,
        editHide: true,
      },
      {
        label: "商品图片",
        prop: 'productPic',
        align: "center",
        type: "image",
        imgPrefix: config.downloadUrl,
        addSlot: true,
        editSlot: true,
        rules: [
          {required: true, message: '请上传商品图片', trigger: 'blur'}
        ]
      },
      {
        label: "商品名称",
        prop: 'productName',
        align: "center",
        rules: [
          {required: true, message: '请输入商品名称', trigger: 'blur'},
        ],
        maxlength: "30"
      },
      {
        label: "商品分类",
        prop: 'productSortId',
        hide: true,
        placeholder: "请选择商品分类",
        search: true,
        type: "select",
        dicData: [],
        rules: [
          {required: true, message: '请选择商品分类', trigger: 'blur'}
        ],
        viewHide: true,
      },
      {
        label: "商品分类",
        prop: 'productSortName',
        align: "center",
        addHide: true,
        editHide: true,
      },
      {
        label: "商品基础(采购)单位",
        prop: 'baseUnit',
        align: "center",
        addSlot: true,
        editSlot: true,
        hide: true,
      },
      {
        label: "库存来源",
        prop: 'sourceTypes',
        align: "center",
        hide: true,
        type: "radio",
        dicData: [
          {
            label: "自身",
            value: 1
          },
          {
            label: "其他",
            value: 2
          },
        ],
        viewSlot: true
      },
      {
        label: "基础单位",
        prop: 'baseUnit',
        align: "center",
        addHide: true,
        editHide: true,
        viewHide: true,
      },
      {
        label: "商品基础(采购)单位",
        prop: 'productUnitId',
        align: "center",
        addSlot: true,
        editSlot: true,
        hide: true,
        rules: [
          {required: true, message: '请选择', trigger: 'blur'}
        ],
        viewHide: true,
      },
      {
        label: "库存来源",
        prop: 'source',
        align: "center",
        slot: true,
        addHide: true,
        editHide: true,
        viewHide: true
      },
      {
        label: "最新采购价",
        prop: 'purchasePriceString',
        align: "center",
        addHide: true,
        editHide: true,
        viewHide: true,
      },
      {
        label: "建议零售价预警",
        prop: 'isAbnormal',
        align: "center",
        type: "switch",
        viewHide: true,
        dicData: [
          {
            label: "异常",
            value: 1
          },
          {
            label: "正常",
            value: 2
          },
        ],
        search: true,
        addHide: true,
        editHide: true,
      },
      {
        label: "状态",
        prop: 'status',
        align: "center",
        type: "switch",
        viewHide: true,
        slot: true,
        dicData: [
          {
            label: "上架",
            value: 1
          },
          {
            label: "下架",
            value: 2
          },
        ],
        search: true,
        addHide: true,
        editHide: true,
      },
      {
        label: "操作",
        prop: "menu",
        align: "center",
        slot: true,
        width: "150",
        addHide: true,
        editHide: true,
        viewHide: true,
      },
    ]
  }
  rules = {
    productName: [
      {required: true, message: '请输入商品名称', trigger: 'blur'},
    ],
    sourceTypes: [
      {required: true, message: '请选择库存来源', trigger: 'blur'},
    ],
    productSortId: [
      {required: true, message: '请选择分类', trigger: 'blur'},
    ],
    productPic: [
      {required: true, message: '请上传图片', trigger: 'blur'},
    ],
    qty: [
      {
        required: true, validator: (rule: any, value: any, callback: any) => {
          if (value === '') {
            callback(new Error('请输入'));
          } else if (!/(^[1-9]\d{0,5}(\.\d{1,2})?$)|(^0(\.\d{1,2})$)/.test(value)) {
            callback(new Error('0~999999.99'));
          } else {
            callback();
          }
        }, trigger: 'blur'
      },
    ],
    suggestPrice: [
      {
        required: true, validator: (rule: any, value: any, callback: any) => {
          if (value === '') {
            callback(new Error('请输入建议零售价'));
          } else if (!/(^[1-9]\d{0,5}(\.\d{1,2})?$)|(^0(\.\d{1,2})$)/.test(value)) {
            callback(new Error('0~999999.99'));
          } else {
            callback();
          }
        }, trigger: 'blur'
      },
    ],
    miniPrice: [
      {
        required: true, validator: (rule: any, value: any, callback: any) => {
          if (value === '') {
            callback();
          } else if (!/(^[1-9]\d{0,5}(\.\d{1,2})?$)|(^0(\.\d{1,2})$)/.test(value)) {
            callback(new Error('0~999999.99'));
          } else {
            callback();
          }
        }, trigger: 'blur'
      },
    ],
    maxPrice: [
      {
        required: true, validator: (rule: any, value: any, callback: any) => {
          if (value === '') {
            callback();
          } else if (!/(^[1-9]\d{0,5}(\.\d{1,2})?$)|(^0(\.\d{1,2})$)/.test(value)) {
            callback(new Error('0~999999.99'));
          } else {
            callback();
          }
        }, trigger: 'blur'
      },
    ],
    miniPrices: [
      {
        required: true, validator: (rule: any, value: any, callback: any) => {
          if (!value) {
            callback(new Error('请输入最小售价'));
            // callback();
          } else if (!/(^[1-9]\d{0,5}(\.\d{1,2})?$)|(^0(\.\d{1,2})$)/.test(value)) {
            callback(new Error('0~999999.99'));
          } else {
            callback();
          }
        }, trigger: 'blur'
      },
    ],
    maxPrices: [
      {
        required: true, validator: (rule: any, value: any, callback: any) => {
          if (!value) {
            callback(new Error('请输入最大售价'));
            // callback();
          } else if (!/(^[1-9]\d{0,5}(\.\d{1,2})?$)|(^0(\.\d{1,2})$)/.test(value)) {
            callback(new Error('0~999999.99'));
          } else {
            callback();
          }
        }, trigger: 'blur'
      },
    ],
    productUnitId: [
      {
        required: true, validator: (rule: any, value: any, callback: any) => {
          if (!value) {
            callback(new Error('请选择'));
          } else {
            callback();
          }
        }, trigger: 'blur'
      },
    ],
  } as any

  /**
   * 打开编辑售价
   */
  async editPrice(row: any, num: number) {
    this.gateList = []
    this.priceUnitList = []
    this.productAttrList = []
    this.tableForm.goodsTableData = []
    this.tableForm.productSn = row.productSn
    this.tableForm.productName = row.productName
    // 获取档口列表
    let gateListRes = await gateGetGateListApi()
    this.gateList = gateListRes
    // 获取商品绑定单位列表
    let priceUnitListRes = await productGetPriceUnitListApi({productId: row.id})
    this.priceUnitList = priceUnitListRes
    // 获取价格属性列表
    let productAttrListRes = await productGetProductAttrListApi()
    this.productAttrList = productAttrListRes
    for (let i = 0; i < this.gateList.length; i++) {
      for (let j = 0; j < this.priceUnitList.length; j++) {
        let property = {} as any
        for (let k = 0; k < this.productAttrList.length; k++) {
          property['miniPrice' + this.productAttrList[k].id] = ''
          property['maxPrice' + this.productAttrList[k].id] = ''
        }
        this.tableForm.goodsTableData.push({
          gateName: this.gateList[i].gateName, //档口名称
          unit: this.priceUnitList[j].unit, // 售价单位
          suggestPrice: '', // 建议零售价
          miniPrice: '', // 最小价格
          maxPrice: '', // 最大价格
          gateId: this.gateList[i].id, // 档口id
          productUnitBindId: this.priceUnitList[j].id, // 商品单位绑定表id
          productId: this.priceUnitList[j].productId, // 商品id
          ...property
        })
      }
    }
    let detailRes = await productQuerySalesPriceDetailsApi({productId: row.id})
    detailRes.forEach((item: any) => {
      for (let i = 0; i < this.tableForm.goodsTableData.length; i++) {
        if (item.gateId == this.tableForm.goodsTableData[i].gateId && item.productUnitBindId == this.tableForm.goodsTableData[i].productUnitBindId) {
          if (item.types == 1) {
            this.tableForm.goodsTableData[i].suggestPrice = item.suggestPrice
            this.tableForm.goodsTableData[i].miniPrice = item.miniPrice
            this.tableForm.goodsTableData[i].maxPrice = item.maxPrice
          } else if (item.types == 2) {
            this.tableForm.goodsTableData[i]['miniPrice' + item.productPriceAttrId] = item.miniPrice
            this.tableForm.goodsTableData[i]['maxPrice' + item.productPriceAttrId] = item.maxPrice
          }
        }
      }
    })
    if (num == 1) {
      this.tableDetail = false
      this.goodsPriceDialogTitle = '编辑商品售价'
    } else {
      this.tableDetail = true
      this.goodsPriceDialogTitle = '商品售价详情'
    }
    this.goodsPriceDialog = true
  }

  /**
   * 编辑
   */
  handleEdit(id: string) {
    this.addDialogTitle = '编辑商品'
    this.baseUnitListDisabled = []
    productGetSortListApi(1).then(e => {
      this.goodsSortList = e
    })
    productGetListApi(1).then(e => {
      this.goodsList = e
    })
    productGetUnitListApi(1).then(e => {
      this.baseUnitList = e
    })
    productDetailApi(id).then(e => {
      if (e) {
        this.modelForm = e
        this.modelForm.productUnitBindDtos = []
        e.productUnitBindVos.forEach((item: any) => {
          this.modelForm.productUnitBindDtos.push({
            types: item.types,
            status: item.status,
            productUnitId: item.productUnitId,
            qty: item.qty,
            unit: item.unit
          })
        })
        this.modelForm.sourceTypes = e.sourceTypes + ''
        if (e.sourceTypes == 2) {
          this.modelForm.productUnitId = e.sourceProductId
        }
        this.baseUnitListDisabled.push(e.productUnitId)
        e.productUnitBindVos.forEach((item: any) => {
          this.baseUnitListDisabled.push(item.productUnitId)
        })
        this.addDialog = true
      }
    })
  }

  objectSpanMethod(e: any) {
    let {row, column, rowIndex, columnIndex} = e
    if (columnIndex === 0) {
      if (rowIndex % this.priceUnitList.length === 0) {
        return {
          rowspan: this.priceUnitList.length,
          colspan: 1
        };
      } else {
        return {
          rowspan: 0,
          colspan: 0
        };
      }
    }
  }

  onChangeSourceTypes(e: any) {
    this.baseUnitListDisabled = []
    if (this.modelForm.productUnitId) {
      this.modelForm.productUnitId = null
    }
    if (this.modelForm.productUnitBindDtos) {
      this.modelForm.productUnitBindDtos = []
    }
    this.modelForm.baseUnit = ''
    if (e == 2) {
      // @ts-ignore
      this.$refs.crudRef.updateFormColumn("productUnitId", {
        label: ""
      })
    } else {
      // @ts-ignore
      this.$refs.crudRef.updateFormColumn("productUnitId", {
        label: "商品基础(采购)单位"
      })
    }
  }

  /**
   * 切换状态
   * @param id
   */
  handleSwitch(id: string) {
    productUpdateStatusApi(id).then(e => {
      if (e) {
        Message.success("操作成功!")
        this.getList()
      }
    })
  }

  /**
   * 上传前检验
   * @param file
   */
  beforeUpload(file: File) {
    if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg") {
      Message.error("请选择正确格式")
      return false
    }
    const isLt = file.size / 1024 / 1024 < 0.5;
    if (!isLt) {
      this.$message.error(`上传图片大小不能超过 500KB!`);
      return false;
    }
  }

  rowSave() {
    // @ts-ignore
    this.$refs['form'].validate((valid) => {
      if (valid) {
        this.goodsLoading = true
        if (this.modelForm.sourceTypes == 2) {
          this.goodsList.forEach((item: any) => {
            if (item.id == this.modelForm.productUnitId) {
              this.modelForm.productUnitId = item.productUnitId
            }
          })
        }

        const form: any = deepCopy(this.modelForm);
        form.productUnitBindDtos.push({
          types: 1,
          status: 1,
          qty: 0,
          unit: this.modelForm.baseUnit,
          productUnitId: this.modelForm.productUnitId
        })
        // 修改
        if (this.modelForm.id) {
          delete form.productUnitBindVos
          productModifyApi(form).then(e => {
            if (e) {
              this.$message.success("操作成功!")
              this.getList()
              this.addDialog = false
              this.goodsLoading = false
            }
          })
        } else {
          productCreateApi(form).then(e => {
            if (e) {
              this.$message.success("操作成功!")
              this.getList()
              this.addDialog = false
              this.goodsLoading = false
            }
          }).catch(() => {
            this.goodsLoading = false
          })
        }
      }
    })
  }


  getList() {
    this.tableLoading = true
    productQueryApi(this.queryParam).then(e => {
      this.tableData = e.list;
      this.tableTotal = e.total;
      this.tableLoading = false
    })
  }

  getProductGetSortList() {
    productGetSortListApi(1).then(e => {
      // @ts-ignore
      this.$refs.crudRef.updateSearchColumn("productSortId", {
        dicData: e.map((item: any) => {
          return {
            label: item.sortName,
            value: item.id
          }
        })
      })
    })
  }

  async openAdd() {
    this.modelForm = {
      productUnitBindDtos: []
    }
    this.baseUnitListDisabled = []
    productGetSortListApi(1).then(e => {
      this.goodsSortList = e
    })
    productGetListApi(1).then(e => {
      this.goodsList = e
    })
    productGetUnitListApi(1).then(e => {
      this.baseUnitList = e
    })

    this.addDialogTitle = '新增商品'
    this.addDialog = true
    // @ts-ignore
    // this.$refs.crudRef.rowAdd()
  }

  unitRemove(index: number, row: any) {
    this.modelForm.productUnitBindDtos.splice(index, 1)
    this.baseUnitListDisabled.splice(this.baseUnitListDisabled.indexOf(row.productUnitId), 1)
  }

  addUnit() {
    if (!this.modelForm.baseUnit) {
      return this.$message.error("请先选择基础单位!")
    }
    this.modelForm.productUnitBindDtos.push({
      // units: this.modelForm.baseUnit,
      types: 2,
      qty: null,
      productUnitId: null,
      unit: null
    })
  }

  /**
   * 选择基础单位触发
   * @param e
   */
  changePro(e: any) {
    this.baseUnitListDisabled = []
    this.baseUnitListDisabled.push(e)
    this.baseUnitList.forEach((item: any) => {
      if (item.id == this.modelForm.productUnitId) {
        this.modelForm.baseUnit = item.unitName
      }
    })
    this.modelForm.productUnitBindDtos = []
  }

  /**
   * 选择商品下拉触发
   * @param e
   */
  changeGoods(e: any) {
    this.goodsList.forEach((item: any) => {
      if (item.id == this.modelForm.productUnitId) {
        this.modelForm.baseUnit = item.baseUnit
        this.modelForm.sourceProductId = item.id
        this.baseUnitListDisabled.push(item.productUnitId)

        // this.modelForm.productUnitId = item.productUnitId
      }
    })
    this.modelForm.productUnitBindDtos = []

  }

  /**
   * 辅助单位下拉框选择触发
   */
  changeProductUnitId(e: any, row: any) {
    this.baseUnitListDisabled.splice(1, this.baseUnitListDisabled.length - 1)
    this.modelForm.productUnitBindDtos.forEach((item: any) => {
      this.baseUnitListDisabled.push(item.productUnitId)
    })
    this.baseUnitList.forEach((item: any) => {
      if (item.id == row.productUnitId) {
        row.unit = item.unitName
      }
    })
  }

  openDetail(row: any, index: number) {
    productDetailApi(row.id as string).then(e => {
      if (e) {
        this.baseUnitDetail = e.baseUnit
        // @ts-ignore
        this.$refs.crudRef.rowView(e, index)
      }
    })
  }

  /**
   * 编辑售价提交
   */
  editSellingPrice() {
    // @ts-ignore
    this.$refs['tableForms'].validate((valid) => {
      if (valid) {
        // 数据校验
        for (let i = 0; i < this.tableForm.goodsTableData.length; i++) {
          if (!(Number(this.tableForm.goodsTableData[i].maxPrice) > Number(this.tableForm.goodsTableData[i].miniPrice))) {
            this.$message.error('建议零售价区间设置有误，最大售价需大于最小售价！')
            return
          }
          if (!(Number(this.tableForm.goodsTableData[i].maxPrice) >= Number(this.tableForm.goodsTableData[i].suggestPrice) && Number(this.tableForm.goodsTableData[i].miniPrice) <= Number(this.tableForm.goodsTableData[i].suggestPrice))) {
            this.$message.error('建议零售价需在区间范围内！')
            return
          }
          for (let j = 0; j < this.productAttrList.length; j++) {
            if (this.tableForm.goodsTableData[i]['maxPrice' + this.productAttrList[j].id] && !this.tableForm.goodsTableData[i]['miniPrice' + this.productAttrList[j].id]) {
              this.$message.error(this.productAttrList[j].attrName + '需同时设置最大售价与最小售价！')
              return
            } else if (!this.tableForm.goodsTableData[i]['maxPrice' + this.productAttrList[j].id] && this.tableForm.goodsTableData[i]['miniPrice' + this.productAttrList[j].id]) {
              this.$message.error(this.productAttrList[j].attrName + '需同时设置最大售价与最小售价！')
              return
            } else if ((this.tableForm.goodsTableData[i]['maxPrice' + this.productAttrList[j].id] || this.tableForm.goodsTableData[i]['miniPrice' + this.productAttrList[j].id])) {
              if (!(Number(this.tableForm.goodsTableData[i]['maxPrice' + this.productAttrList[j].id]) > Number(this.tableForm.goodsTableData[i]['miniPrice' + this.productAttrList[j].id]))) {
                this.$message.error(this.productAttrList[j].attrName + '设置有误，最大售价需大于最小售价！')
                return
              }
            }
          }
        }
        this.loading = true
        let productPriceList = [] as any
        this.tableForm.goodsTableData.forEach((item: any) => {
          productPriceList.push({
            gateId: item.gateId,
            productId: item.productId,
            productUnitBindId: item.productUnitBindId,
            types: 1,
            suggestPrice: item.suggestPrice,
            maxPrice: item.maxPrice,
            miniPrice: item.miniPrice
          })
          // 价格属性列表循环
          for (let i = 0; i < this.productAttrList.length; i++) {
            if (item['maxPrice' + this.productAttrList[i].id] || item['miniPrice' + this.productAttrList[i].id]) {
              productPriceList.push({
                gateId: item.gateId,
                productId: item.productId,
                productUnitBindId: item.productUnitBindId,
                types: 2,
                suggestPrice: item.suggestPrice,
                productPriceAttrId: this.productAttrList[i].id,
                maxPrice: item['maxPrice' + this.productAttrList[i].id],
                miniPrice: item['miniPrice' + this.productAttrList[i].id]
              })
            }
          }
        })
        productEditSellingPriceApi(productPriceList).then(e => {
          if (e) {
            this.$message.success("操作成功!")
            this.getList()
            this.goodsPriceDialog = false
            this.loading = false
          }
        }).catch(() => {
          this.loading = false
        })

      }
    })
  }

  /**
   * 一键配置
   */
  copy() {
    let listData = [] as any
    this.tableForm.goodsTableData.forEach((item: any, index: number) => {
      // 跳过第一个档口
      if (item.gateId == this.tableForm.goodsTableData[0].gateId) {
        listData.push(item)
      }
    })
    this.tableForm.goodsTableData.forEach((item: any, index: number) => {
      // 跳过第一个档口
      if (item.gateId !== this.tableForm.goodsTableData[0].gateId) {
        console.log(item)
        for (let i = 0; i < listData.length; i++) {
          console.log(item.productUnitBindId, listData[i].productUnitBindId)
          if (item.productUnitBindId == listData[i].productUnitBindId) {
            this.tableForm.goodsTableData[index].suggestPrice = listData[i].suggestPrice
            this.tableForm.goodsTableData[index].maxPrice = listData[i].maxPrice
            this.tableForm.goodsTableData[index].miniPrice = listData[i].miniPrice
            for (let j = 0; j < this.productAttrList.length; j++) {
              this.tableForm.goodsTableData[index]['miniPrice' + this.productAttrList[j].id] = listData[i]['miniPrice' + this.productAttrList[j].id]
              this.tableForm.goodsTableData[index]['maxPrice' + this.productAttrList[j].id] = listData[i]['maxPrice' + this.productAttrList[j].id]
            }
          }
        }
      }
    })
  }

  exportExcel() {
    productExportApi(this.queryParam).then(e => {
      exportFile(e, "商品信息.xlsx")
    })
  }


  created() {
    this.getList()
    this.getProductGetSortList()
  }
}
